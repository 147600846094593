body {
  text-align: center;
  background-color: #2a303c;
  margin-bottom: 100px;
}

.jexcel > tbody > tr > td {
  font-family: Heiti SC;
  font-size: 20px;
}

.jexcel > thead > tr > td {
  color: #dee3ed;
  background-color: #3e4f6f;
  padding: 10px;
  font-family: sans-serif;
  font-size: 20px;
}

.jexcel > tfoot > tr > td {
  font-family: sans-serif;
  font-size: 20px;
}

.sum {
  height: 50px;
  width: 50px;
  color: #dee3ed;
  padding: 1em;
  font-size: 30px;
  font-weight: bold;
  background-color: #3e4f6f !important;
}

#heading {
  text-align: center;
  color: #afc9ff;
  border-radius: 50px;
  margin: 30px auto;
  font-family: Fredoka One, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}

#about {
  color: #0e2a67;
  text-align: left;
  width: 790px;
  background-color: #d2d3d5;
  border-radius: 7px;
  margin: 0 auto 30px;
  padding: 30px;
  font-family: Heiti SC;
  font-size: 20px;
}

#ig_button {
  width: 90px;
  height: 25px;
  color: #0f182c;
  background: #afc9ff url("instagram.5e1a1dca.svg") 10px / 45px 45px no-repeat;
  border-radius: 15px;
  margin-left: 20px;
  padding: 15px 15px 15px 65px;
  font-family: Fredoka, sans-serif;
  font-size: 1.1em;
  line-height: 22px;
  text-decoration: none;
  display: inline-block;
}

#home_button {
  width: 300px;
  height: 100px;
  background: url("logo.2a4eef38.png") 10px / 250px 100px no-repeat;
  display: block;
}

a:visited {
  color: #000;
}

#ig_button:hover, #items_button:hover {
  background-color: #e0e0e0;
}

#logo {
  width: 500px;
  text-align: center;
  border-radius: 7px;
  margin: 30px auto;
  padding: 50px 10px;
}

#home {
  text-align: center;
  background-color: #2a303c;
  margin-bottom: 100px;
}

#items_button {
  width: 290px;
  height: 30px;
  color: #000;
  background-color: #afc9ff;
  border-radius: 15px;
  margin: 30px auto 0;
  padding: 15px 15px 15px 10px;
  font-family: Fredoka, sans-serif;
  font-size: 1.5em;
  text-decoration: none;
}

#link {
  color: #0e2a67;
  text-align: left;
  width: 150px;
  background-color: #d2d3d5;
  border-radius: 7px;
  margin: 0 auto 30px;
  padding: 30px;
  font-family: Heiti SC;
  font-size: 20px;
}

/*# sourceMappingURL=table.474d8e82.css.map */
