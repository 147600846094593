body {
    background-color: hsl(220, 18%, 20%);
    text-align: center;
    margin-bottom: 100px;
}

.jexcel > tbody > tr > td {
    font-family: 'Heiti SC';
    font-size: 20px;
}

.jexcel > thead > tr > td {
    font-family: sans-serif;
    background-color: hsl(220, 28%, 34%);

    /* background-color: rgba(138, 202, 245, 0.89);  */
    padding: 10px;
    /* color: white; */
    color: hsl(220, 30%, 90%);
    /* color: rgba(56, 53, 53, 0.863); */
    font-size: 20px;
    /* font-weight: bold; */
}

.jexcel > tfoot > tr > td {
    font-family: sans-serif;
    font-size: 20px;
}

.sum {
    background-color: hsl(220, 28%, 34%) !important; 
    height: 50px;
    width: 50px;
    font-size: 30px;
    font-weight: bold;
    padding: 1em;
    color: hsl(220, 30%, 90%);
}

#heading {
    margin: 0px auto;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    font-family: 'Fredoka One', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: #AFC9FF;
    border-radius: 50px;
}

#about {
    margin: 0px auto;
    background-color: #D2D3D5;
    color: #0E2A67;
    border-radius: 7px;
    padding: 30px;
    text-align: left;
    width: 790px;
    font-family: 'Heiti SC';
    font-size: 20px;
    margin-bottom: 30px;
}

#ig_button {
    background: no-repeat 10px center/45px 45px url('../img/instagram.svg');
    display: block;
    width: 90px;
    height: 25px;
    padding: 15px 15px 15px 65px; 
    text-decoration: none;
    color: #0F182C;
    font-size: 1.1em;
    font-family: 'Fredoka', sans-serif;
    line-height: 22px;
    background-color: #AFC9FF;
    border-radius: 15px;
    /* border: 1px solid #c0c0c0; */
    display:inline-block;
    margin-left: 20px;
}

#home_button {
    background: no-repeat 10px center/250px 100px url('../img/logo.png');
    display: block;
    width: 300px;
    height: 100px;        
}

a:visited {
    color:black;
}

#ig_button:hover, #items_button:hover {
    background-color: #e0e0e0;
}

#logo {
    margin: 0px auto;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 500px;
    text-align: center;
    padding: 50px 10px;
    /* background-color: rgba(255, 255, 255, 0.493); */
    border-radius: 7px;
}

#home {
    background-color: hsl(220, 18%, 20%);
    text-align: center;
    margin-bottom: 100px;
}

#items_button {
    margin: 0px auto;
    margin-top: 30px;
    width: 290px;
    height: 30px;
    padding: 15px 15px 15px 10px; 
    text-decoration: none;
    color: black;
    font-size: 1.5em;
    font-family: 'Fredoka', sans-serif;  
    background-color: #AFC9FF;
    border-radius: 15px;
}

#link {
    margin: 0px auto;
    background-color: #D2D3D5;
    color: #0E2A67;
    border-radius: 7px;
    padding: 30px;
    text-align: left;
    width: 150px;
    font-family: 'Heiti SC';
    font-size: 20px;
    margin-bottom: 30px;
}